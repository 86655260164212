
import { defineComponent } from "vue";
import { NavLink, pageManagerModule } from "@/store/modules/pageManager";
import Fireworks from "@/components/Fireworks.vue";
import { authModule } from "@/store/modules/auth";
import { User } from "../../../types/User";
export default defineComponent({
  name: "AppLayout",
  components: { Fireworks },
  computed: {
    pageTitle(): string {
      return pageManagerModule.pageTitle;
    },
    navLinks(): NavLink[] {
      return pageManagerModule.navLinks;
    },
    enableFireworks(): boolean {
      return pageManagerModule.enableFireworks;
    },
    user(): User {
      return (
        authModule.authedUser ||
        ({ image: "", name: "", players: [{ hashid: "NEVME" }] } as User)
      );
    },
    activeRoute(): NavLink | null {
      return (
        pageManagerModule.navLinks.find(
          link => link.name === this.$route.name
        ) || null
      );
    }
  },
  data() {
    return {
      userToggle: false,
      menuToggle: false
    };
  }
});
