import { convertPlayers, Player } from "./Player";

export interface User {
  id: number;
  name: string;
  image: string;
  players: Player[];
  admin: boolean;
}

export const convertUser = (u: any): User => ({
  id: u.id,
  name: u.name,
  image: u.image,
  players: convertPlayers(u.players || []),
  admin: u.admin
});
