import { convertPrizes, Prize } from "./Prize";

export interface Player {
  hashid: string;
  name: string;
  level: number;
  prizes: Prize[];
  image: string;
}

export const convertPlayer = (p: any): Player => ({
  hashid: p.hashid,
  name: p.name,
  level: p.level,
  prizes: convertPrizes(p.prizes || []),
  image: p.image
});

export const convertPlayers = (ps: any[]) => ps.map(convertPlayer);
