import {
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";

export interface NavLink {
  title: string;
  name: string;
  exact: boolean;
}

@Module({ dynamic: true, store, name: "pageManager", namespaced: true })
export class PageManager extends VuexModule {
  _pageTitle = "Home";
  _navLinks: NavLink[] = [
    { title: "Gala Participants", name: "Home", exact: true },
    { title: "Chat", name: "Chat", exact: false },
    { title: "Ball Drop", name: "DropBall", exact: false }
  ];
  _enableFireworks = false;

  @Mutation
  changePageTitle(to: string) {
    this._pageTitle = to;
  }

  @Mutation
  changeEnableFireworks(to: boolean) {
    this._enableFireworks = to;
  }

  get pageTitle() {
    return this._pageTitle;
  }

  get enableFireworks() {
    return this._enableFireworks;
  }

  get navLinks() {
    return this._navLinks;
  }
}

export const pageManagerModule = getModule(PageManager);
