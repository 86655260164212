import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import { User } from "../../../types/User";
import { checkAuthStatus } from "@/api/queries/checkAuthStatus";
import store from "@/store";
import { login } from "@/api/queries/login";

@Module({ dynamic: true, store, name: "auth", namespaced: true })
export class Auth extends VuexModule {
  initialLoadFinished = false;
  user: User | null = null;

  @Mutation
  initialLoadEnd(user = null) {
    this.user = user;
    this.initialLoadFinished = true;
    return true;
  }

  @Action({ commit: "initialLoadEnd" })
  async initialLoadBegin() {
    return (await checkAuthStatus()).user;
  }

  @Action
  async handleLogin(data: { email: string; password: string }) {
    const res = await login(data);

    if (res.success) {
      await this.context.dispatch("initialLoadBegin");
    }

    return res;
  }

  get isUserAuthenticated(): boolean {
    return !!this.user;
  }

  get authedUser() {
    return this.user;
  }
}

export const authModule = getModule(Auth);
