import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";
import { ChatMessage, convertChatMessages } from "../../../types/ChatMessage";

interface ChatGetMessagesResponse {
  success: boolean;
  messages: ChatMessage[];
}

export async function getChatMessages(): Promise<ChatGetMessagesResponse> {
  const res: ChatGetMessagesResponse = { success: true, messages: [] };
  try {
    const { data } = await spaxios.get(Routes.CHAT_GET_MESSAGES);
    res.messages = convertChatMessages(data.messages);
  } catch (e) {
    console.log(e);
    res.success = false;
  }

  return res;
}
