<template>
  <template v-if="initialLoadFinished">
    <template v-if="isUserAuthenticated">
      <socket-handlers />
      <AppLayout>
        <router-view />
      </AppLayout>
    </template>
    <login-form v-else />
  </template>
  <template v-else>
    <LoadingLayout />
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { Auth } from "@/store/modules/auth";
import LoginForm from "@/components/LoginForm.vue";
import SocketHandlers from "@/components/SocketHandlers.vue";
import AppLayout from "@/components/layout/AppLayout.vue";
import LoadingLayout from "@/components/layout/LoadingLayout.vue";

const auth = getModule(Auth);

export default defineComponent({
  name: "App",
  components: { LoadingLayout, AppLayout, SocketHandlers, LoginForm },
  computed: {
    initialLoadFinished(): boolean {
      return auth.initialLoadFinished;
    },
    isUserAuthenticated(): boolean {
      return auth.isUserAuthenticated;
    }
  },
  beforeCreate() {
    auth.initialLoadBegin();
  }
});
</script>

<style lang="scss"></style>
