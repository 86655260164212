
import { defineComponent } from "vue";
import { authModule } from "@/store/modules/auth";
import { objErrorReducer } from "@/helpers/objErrorReducer";

export default defineComponent({
  name: "LoginForm",
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: ""
      },
      errors: {
        email: "",
        password: "",
        system: ""
      }
    };
  },
  methods: {
    clearErrors() {
      this.errors = { email: "", password: "", system: "" };
    },
    async submitLogin() {
      if (this.loading) {
        return false;
      }

      this.clearErrors();
      this.loading = true;
      const res = await authModule.handleLogin(this.form);

      if (!res.success && res.errors) {
        this.errors.email = objErrorReducer(res.errors, "email");
        this.errors.password = objErrorReducer(res.errors, "password");
        this.errors.system = objErrorReducer(res.errors, "system");
      }

      this.loading = false;
    }
  }
});
