import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { specho } from "@/api/specho";
import { Channels } from "@/api/routes";

@Module({ dynamic: true, store, name: "socketManager", namespaced: true })
export class SocketManager extends VuexModule {
  connected = false;

  @Mutation
  modifyConnected(to: boolean) {
    this.connected = to;
  }

  @Action
  async joinChannel(channel: Channels) {
    if (!this.connected) {
      specho.connect();
      this.context.commit("modifyConnected", true);
    }

    return specho.private(channel);
  }

  @Action
  async leaveChannel(channel: Channels) {
    return specho.leave(channel);
  }
}

export const socketManagerModule = getModule(SocketManager);
