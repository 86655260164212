<template>
  <div
    class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-12 w-auto"
        src="../assets/disco.svg"
        alt="Workflow"
      />
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Sign in with your Sorority login
      </h2>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="error-msg" :class="{ invisible: !errors.system }">
          {{ errors.system }}
        </div>
        <form class="space-y-6" method="POST" @submit.prevent="submitLogin">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                v-model="form.email"
                id="email"
                name="email"
                type="email"
                autocomplete="email"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
              />

              <div class="error-msg" :class="{ invisible: !errors.email }">
                {{ errors.email }}
              </div>
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div class="mt-1">
              <input
                v-model="form.password"
                id="password"
                name="password"
                type="password"
                autocomplete="current-password"
                required
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
              />
            </div>
            <div class="error-msg" :class="{ invisible: !errors.password }">
              {{ errors.password }}
            </div>
          </div>

          <div>
            <button
              type="submit"
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
            >
              <span v-if="loading" class="italic">Signing in...</span>
              <span v-else>Sign In</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { authModule } from "@/store/modules/auth";
import { objErrorReducer } from "@/helpers/objErrorReducer";

export default defineComponent({
  name: "LoginForm",
  data() {
    return {
      loading: false,
      form: {
        email: "",
        password: ""
      },
      errors: {
        email: "",
        password: "",
        system: ""
      }
    };
  },
  methods: {
    clearErrors() {
      this.errors = { email: "", password: "", system: "" };
    },
    async submitLogin() {
      if (this.loading) {
        return false;
      }

      this.clearErrors();
      this.loading = true;
      const res = await authModule.handleLogin(this.form);

      if (!res.success && res.errors) {
        this.errors.email = objErrorReducer(res.errors, "email");
        this.errors.password = objErrorReducer(res.errors, "password");
        this.errors.system = objErrorReducer(res.errors, "system");
      }

      this.loading = false;
    }
  }
});
</script>

<style scoped>
.error-msg {
  @apply text-red-400 text-sm font-medium;
}
</style>
