<template>
  <LoadingLayout v-show="!finishedInitialLoad" />
  <div
    class="bg-gray-50 overflow-hidden shadow rounded-lg divide-y divide-gray-200 mb-5"
  >
    <div class="px-4 py-5 sm:px-6">
      Favorite Participants
    </div>
    <div class="px-4 py-5 sm:p-6">
      <input
        type="text"
        class="block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md mb-5"
        placeholder="Enter Player ID Here..."
        v-model="this.form.id"
      />
      <button
        class="bg-indigo-600 mb-2 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600"
        @click="addFavorite"
      >
        Add
      </button>
      <div v-if="favorites.length < 1">You have 0 favorites added.</div>
      <ParticipantBlock v-else :users="favorites" :remove="true" />
    </div>
  </div>
  <ParticipantBlock :users="participants" :remove="false" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { participantsModule } from "@/store/modules/participants";
import { Player } from "../../../types/Player";
import LoadingLayout from "@/components/layout/LoadingLayout.vue";
import ParticipantBlock from "@/components/participants/ParticipantBlock.vue";

export default defineComponent({
  name: "ParticipantsDisplay",
  components: { ParticipantBlock, LoadingLayout },
  data() {
    return {
      form: {
        id: ""
      }
    };
  },
  computed: {
    participants(): Player[] {
      return participantsModule.participants;
    },
    finishedInitialLoad(): boolean {
      return participantsModule.finishedInitialLoad;
    },
    favorites(): Player[] {
      return participantsModule.favoriteParticipants;
    }
  },
  methods: {
    async addFavorite() {
      await participantsModule.addFavorites(this.form.id);
    }
  }
});
</script>

<style scoped></style>
