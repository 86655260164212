
import { defineComponent } from "vue";
import { participantsModule } from "@/store/modules/participants";

export default defineComponent({
  name: "ParticipantBlock",
  props: ["users", "remove"],
  methods: {
    async removeFavorite(id: string) {
      await participantsModule.removeFavorites(id);
    }
  }
});
