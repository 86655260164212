import "pusher-js";
import Echo from "laravel-echo";
import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";

const noop = (...a: any[]) => {
  return a;
};

function createSpecho() {
  return new Echo({
    broadcaster: "pusher",
    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    authorizer: (channel: any, options: any) => {
      noop(options);

      return {
        authorize: (socketId: any, callback: any) => {
          spaxios
            .post(Routes.BROADCAST_AUTH, {
              // eslint-disable-next-line @typescript-eslint/camelcase
              socket_id: socketId,
              // eslint-disable-next-line @typescript-eslint/camelcase
              channel_name: channel.name
            })
            .then(response => {
              callback(false, response.data);
            })
            .catch(error => {
              callback(true, error);
            });
        }
      };
    }
  });
}

export const specho = createSpecho();
