<template>
  <ul
    class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
  >
    <li
      class="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
      v-for="user in users"
      :key="user.hashid"
    >
      <div class="w-full flex items-center justify-between p-2 space-x-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3
              class="text-gray-900 text-sm font-medium truncate cursor-pointer"
            >
              <a
                :href="`https://sororitywars.app/game/profile/${user.hashid}`"
                target="_BLANK"
              >
                {{ user.name }}
              </a>
            </h3>
          </div>
          <p class="mt-1 text-gray-500 text-sm truncate">
            Level {{ user.level }}
          </p>
          <button v-if="this.remove" @click="removeFavorite(user.hashid)">
            Remove Favorite
          </button>
        </div>
        <img
          class="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
          :src="user.image"
          alt=""
        />
      </div>
      <div>
        <div class="-mt-px">
          <div class="w-full text-sm">
            <div v-for="prize in user.prizes" :key="prize.id" class="p-1">
              {{ prize.name }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { participantsModule } from "@/store/modules/participants";

export default defineComponent({
  name: "ParticipantBlock",
  props: ["users", "remove"],
  methods: {
    async removeFavorite(id: string) {
      await participantsModule.removeFavorites(id);
    }
  }
});
</script>

<style scoped></style>
