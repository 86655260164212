import {
  convertChatMessageContentBlocks,
  SupportedChatMessageBlockTypes
} from "./ChatMessageContentBlock";

export interface ChatMessageContent {
  id: number;
  type: "paragraph";
  blocks: SupportedChatMessageBlockTypes[];
}

export const convertChatMessageContent = (c: any): ChatMessageContent => ({
  id: c.id,
  type: c.type,
  blocks: convertChatMessageContentBlocks(c.blocks)
});

export const convertChatMessageContents = (cs: any[]) =>
  cs.map(convertChatMessageContent);
