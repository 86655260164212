export interface Prize {
  id: number;
  name: string;
}

export const convertPrize = (p: any): Prize => ({
  id: p.id,
  name: p.name
});

export const convertPrizes = (ps: any[]) => ps.map(convertPrize);
