import { ChatUser, convertChatUser } from "./ChatUser";
import {
  ChatMessageContent,
  convertChatMessageContents
} from "./ChatMessageContent";

export interface ChatMessage {
  id: number;
  preview: string;
  createdAt: string;
  author: ChatUser;
  contents: ChatMessageContent[];
}

export const convertChatMessage = (c: any): ChatMessage => ({
  id: c.id,
  preview: c.preview,
  createdAt: c.created_at,
  author: convertChatUser(c.author),
  contents: convertChatMessageContents(c.contents)
});

export const convertChatMessages = (cs: any[]) => cs.map(convertChatMessage);
