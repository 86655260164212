import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Player } from "../../../types/Player";
import { getEventParticipants } from "@/api/queries/getEventParticipants";
import { spstorage } from "@/helpers/spstorage";

@Module({ dynamic: true, store, name: "participants", namespaced: true })
export class Participants extends VuexModule {
  all: Record<string, Player> = {};
  ids: string[] = [];
  initialLoadFinished = false;
  favoriteIds: string[] = [];

  @Mutation
  setParticipants(participants: Player | Player[]) {
    if (Array.isArray(participants)) {
      this.all = participants.reduce((o, p) => {
        o[p.hashid] = p;
        return o;
      }, {} as Record<string, Player>);

      this.ids = participants.map(p => p.hashid);
    } else {
      this.all[participants.hashid] = participants;

      if (!this.ids.includes(participants.hashid)) {
        this.ids.push(participants.hashid);
      }
    }
  }

  @MutationAction
  async addFavorites(favs: string | string[]) {
    let favoriteIds = [
      ...((this.state as unknown) as Participants).favoriteIds
    ];

    console.log(favoriteIds, favs);

    if (Array.isArray(favs)) {
      favoriteIds.push(...favs);
    } else {
      favoriteIds.push(favs);
    }

    favoriteIds = favoriteIds.filter((i, n) => favoriteIds.indexOf(i) === n);

    await spstorage.setItem("favs", favoriteIds);

    return { favoriteIds };
  }

  @MutationAction
  async removeFavorites(favs: string | string[]) {
    let favoriteIds = [
      ...((this.state as unknown) as Participants).favoriteIds
    ];
    if (Array.isArray(favs)) {
      favoriteIds = favoriteIds.filter(i => !favs.includes(i));
    } else {
      favoriteIds = favoriteIds.filter(i => i !== favs);
    }

    await spstorage.setItem("favs", favoriteIds);

    return { favoriteIds };
  }

  @MutationAction
  async fetchFavorites() {
    let favoriteIds = await spstorage.getItem<string[]>("favs");

    if (!favoriteIds) {
      favoriteIds = [];
    }

    return { favoriteIds };
  }

  @Mutation
  setInitialLoadFinished(to: boolean) {
    this.initialLoadFinished = to;
  }

  @Action({ commit: "setParticipants" })
  async fetchParticipants() {
    const res = await getEventParticipants();

    if (!this.initialLoadFinished) {
      this.context.commit("setInitialLoadFinished", true);
    }
    return res.players;
  }

  get participants() {
    return this.ids.map(pid => this.all[pid]);
  }

  get favoriteParticipants() {
    return this.favoriteIds.map(pid => this.all[pid]).filter(i => i);
  }

  get finishedInitialLoad() {
    return this.initialLoadFinished;
  }
}

export const participantsModule = getModule(Participants);
