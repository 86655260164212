import { convertUser, User } from "../../../types/User";
import axios from "axios";
import { Routes } from "@/api/routes";

interface CheckAuthStatusResponse {
  auth: boolean;
  success: boolean;
  user?: User;
}

export async function checkAuthStatus(): Promise<CheckAuthStatusResponse> {
  const response: CheckAuthStatusResponse = { auth: false, success: true };

  try {
    const apiRes = await axios.post(Routes.AUTH_CHECK);
    response.user = convertUser(apiRes.data.user);
  } catch (e) {
    response.success = false;
  }

  return response;
}
