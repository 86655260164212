export enum Routes {
  SANCTUM = "/sanctum/csrf-cookie",
  LOGIN = "/api/auth",
  AUTH_CHECK = "/api/auth/check-status",
  GET_PARTICIPANTS = "/api/event/participants",
  BROADCAST_AUTH = "/api/broadcasting/auth",
  CHAT_MENTION_SEARCH = "/api/chat/mention-search",
  CHAT_SEND_MESSAGE = "/api/chat/send-message",
  CHAT_GET_MESSAGES = "/api/chat/messages"
}

export enum Channels {
  BALL_DROP = "ball-drop",
  PARTICIPANTS = "participants",
  CHAT = "chat"
}
