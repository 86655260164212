export interface ChatUser {
  userid: number;
  id: string;
  name: string;
  image: string;
  admin: boolean;
}

export const convertChatUser = (cu: any): ChatUser => ({
  userid: cu.userid,
  id: cu.id,
  name: cu.name,
  image: cu.image,
  admin: cu.admin
});

export const convertChatUsers = (cus: any[]) => cus.map(convertChatUser);
