import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { ChatMessage } from "../../../types/ChatMessage";
import { getChatMessages } from "@/api/queries/getChatMessages";

@Module({ dynamic: true, store, name: "chatManager", namespaced: true })
export class ChatManager extends VuexModule {
  messages: Record<number, ChatMessage> = {};
  messageIds: number[] = [];
  initialLoadFinished = false;

  @Mutation
  addMessages(messages: ChatMessage | ChatMessage[]) {
    const messageIds = [...this.messageIds];

    if (Array.isArray(messages)) {
      messages.forEach(message => {
        messageIds.push(message.id);
        this.messages[message.id] = message;
      });
    } else {
      this.messages[messages.id] = messages;
      messageIds.push(messages.id);
    }

    messageIds.sort((a, b) => a - b);
    this.messageIds = messageIds.filter((x, i) => messageIds.indexOf(x) === i);
  }

  @Mutation
  changeInitialLoadFinished(to: boolean) {
    this.initialLoadFinished = to;
  }

  @Action({ commit: "addMessages" })
  async getMessages() {
    if (!this.initialLoadFinished) {
      this.context.commit("changeInitialLoadFinished", true);
    }

    const res = await getChatMessages();
    return res.messages;
  }

  get chatMessages() {
    return this.messageIds.map(mid => this.messages[mid]);
  }

  get finishedInitialLoad() {
    return this.initialLoadFinished;
  }
}

export const chatManagerModule = getModule(ChatManager);
