import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";
import { convertPlayers, Player } from "../../../types/Player";

interface GetEventParticipantsResponse {
  success: boolean;
  players: Player[];
}

export async function getEventParticipants(): Promise<
  GetEventParticipantsResponse
> {
  const response: GetEventParticipantsResponse = { players: [], success: true };
  try {
    const res = await spaxios.post(Routes.GET_PARTICIPANTS);
    response.players = convertPlayers(res.data.players);
  } catch (e) {
    response.success = false;
  }

  return response;
}
