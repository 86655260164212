<template>
  <div class="hidden" />
</template>

<script>
import { defineComponent } from "vue";
import { Channels } from "@/api/routes";
import { socketManagerModule } from "@/store/modules/socketManager";
import { ParticipantEvents } from "@/api/events/ParticipantEvents";
import { participantsModule } from "@/store/modules/participants";
import { convertPlayer } from "../../types/Player";
import { ChatEvents } from "@/api/events/ChatEvents";
import { chatManagerModule } from "@/store/modules/chatManager";
import { convertChatMessage } from "../../types/ChatMessage";

export default defineComponent({
  name: "SocketHandlers",
  async mounted() {
    const participantsChannel = await socketManagerModule.joinChannel(
      Channels.PARTICIPANTS
    );

    const chatChannel = await socketManagerModule.joinChannel(Channels.CHAT);

    chatChannel.listen(ChatEvents.MESSAGE_CREATED, e => {
      chatManagerModule.addMessages(convertChatMessage(e.message));
    });

    participantsChannel.listen(ParticipantEvents.UPDATED, e => {
      participantsModule.setParticipants(convertPlayer(e.user));
    });
    await participantsModule.fetchFavorites();
  },
  beforeUnmount() {
    socketManagerModule.leaveChannel(Channels.PARTICIPANTS);
  }
});
</script>
