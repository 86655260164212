import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/drop-ball",
    name: "DropBall",
    component: () =>
      import(/* webpackChunkName: "drop-ball-page" */ "../views/DropBall.vue")
  },
  {
    path: "/chat",
    name: "Chat",
    component: () =>
      import(/* webpackChunkName: "chat-page" */ "../views/Chat.vue")
  }
];

const router = createRouter({
  history: createWebHistory("/"),
  routes
});

export default router;
