
import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { Auth } from "@/store/modules/auth";
import LoginForm from "@/components/LoginForm.vue";
import SocketHandlers from "@/components/SocketHandlers.vue";
import AppLayout from "@/components/layout/AppLayout.vue";
import LoadingLayout from "@/components/layout/LoadingLayout.vue";

const auth = getModule(Auth);

export default defineComponent({
  name: "App",
  components: { LoadingLayout, AppLayout, SocketHandlers, LoginForm },
  computed: {
    initialLoadFinished(): boolean {
      return auth.initialLoadFinished;
    },
    isUserAuthenticated(): boolean {
      return auth.isUserAuthenticated;
    }
  },
  beforeCreate() {
    auth.initialLoadBegin();
  }
});
