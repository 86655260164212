<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img
                class="h-8 w-8"
                src="https://nye2021.sororitywars.app/assets/app/img/disco.d9ab6f1d.svg"
                alt="Workflow"
              />
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline space-x-4">
                <router-link
                  active-class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                  class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  v-for="navLink in navLinks"
                  :exact="navLink.exact"
                  :to="{ name: navLink.name }"
                  :key="navLink.name"
                >
                  {{ navLink.title }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden md:block">
            <div class="ml-4 flex items-center md:ml-6">
              <div class="ml-3 relative">
                <div>
                  <button
                    class="max-w-xs bg-gray-800 rounded-full flex items-center text-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                    id="user-menu"
                    aria-haspopup="true"
                    @click="userToggle = !userToggle"
                  >
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" :src="user.image" />
                  </button>
                </div>
                <transition
                  name="fade"
                  enter-active-class="transition ease-out duration-100"
                  enter-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <div
                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="user-menu"
                    v-show="userToggle"
                  >
                    <a
                      :href="
                        `https://sororitywars.app/game/profile/${user.players[0].hashid}`
                      "
                      target="_BLANK"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      >Your Profile</a
                    >

                    <a
                      href="#"
                      class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      role="menuitem"
                      >Sign out</a
                    >
                  </div>
                </transition>
              </div>
            </div>
          </div>
          <div class="-mr-2 flex md:hidden">
            <!-- Mobile menu button -->
            <button
              @click="menuToggle = !menuToggle"
              class="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
            >
              <span class="sr-only">Open main menu</span>
              <svg
                v-show="!menuToggle"
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!--
                Heroicon name: x

                Menu open: "block", Menu closed: "hidden"
              -->
              <svg
                v-show="menuToggle"
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div v-show="menuToggle" class="md:hidden">
        <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <router-link
            active-class="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
            class="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
            v-for="navLink in navLinks"
            :exact="navLink.exact"
            :to="{ name: navLink.name }"
            :key="navLink.name"
          >
            {{ navLink.title }}
          </router-link>
        </div>
        <div class="pt-4 pb-3 border-t border-gray-700">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <img class="h-10 w-10 rounded-full" :src="user.image" />
            </div>
            <div class="ml-3">
              <div class="text-base font-medium text-white">
                {{ user.name }}
              </div>
            </div>
          </div>
          <div class="mt-3 px-2 space-y-1">
            <a
              :href="
                `https://sororitywars.app/game/profile/${user.players[0].hashid}`
              "
              target="_BLANK"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >Your Profile</a
            >

            <a
              href="#"
              class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
              >Sign out</a
            >
          </div>
        </div>
      </div>
    </nav>
    <Fireworks v-if="enableFireworks" />
    <header class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <h1 class="text-lg leading-6 font-semibold text-gray-900">
          <template v-if="activeRoute">
            {{ activeRoute.title }}
          </template>
          <template v-else>
            404 Page not found
          </template>
        </h1>
      </div>
    </header>
    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <!-- Replace with your content -->
        <slot />
        <!-- /End replace -->
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { NavLink, pageManagerModule } from "@/store/modules/pageManager";
import Fireworks from "@/components/Fireworks.vue";
import { authModule } from "@/store/modules/auth";
import { User } from "../../../types/User";
export default defineComponent({
  name: "AppLayout",
  components: { Fireworks },
  computed: {
    pageTitle(): string {
      return pageManagerModule.pageTitle;
    },
    navLinks(): NavLink[] {
      return pageManagerModule.navLinks;
    },
    enableFireworks(): boolean {
      return pageManagerModule.enableFireworks;
    },
    user(): User {
      return (
        authModule.authedUser ||
        ({ image: "", name: "", players: [{ hashid: "NEVME" }] } as User)
      );
    },
    activeRoute(): NavLink | null {
      return (
        pageManagerModule.navLinks.find(
          link => link.name === this.$route.name
        ) || null
      );
    }
  },
  data() {
    return {
      userToggle: false,
      menuToggle: false
    };
  }
});
</script>

<style scoped></style>
