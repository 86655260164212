import { ChatUser, convertChatUser } from "./ChatUser";

export enum ChatMessageContentBlockType {
  Text = "text",
  Mention = "mention",
  Softbreak = "softbreak"
}

export interface ChatMessageContentBlockBase {
  id: number;
  content: string;
  type: ChatMessageContentBlockType;
}

export interface ChatMessageContentBlockMention
  extends ChatMessageContentBlockBase {
  type: ChatMessageContentBlockType.Mention;
  mention: ChatUser;
}

export interface ChatMessageContentBlockText
  extends ChatMessageContentBlockBase {
  type: ChatMessageContentBlockType.Text;
}

export interface ChatMessageContentBlockSoftbreak
  extends ChatMessageContentBlockBase {
  type: ChatMessageContentBlockType.Softbreak;
}

export type SupportedChatMessageBlockTypes =
  | ChatMessageContentBlockMention
  | ChatMessageContentBlockSoftbreak
  | ChatMessageContentBlockText;

const converChatMessageContentBlockBase = (
  c: any
): ChatMessageContentBlockBase => ({
  type: c.type,
  content: c.content || "",
  id: c.id
});

const convertChatMessageContentBlockMention = (
  c: any
): ChatMessageContentBlockMention => ({
  ...(converChatMessageContentBlockBase(c) as any),
  mention: convertChatUser(c.mention)
});

const convertChatMessageContentBlock = (
  c: any
): SupportedChatMessageBlockTypes => {
  if (c.type === ChatMessageContentBlockType.Mention) {
    return convertChatMessageContentBlockMention(c);
  }
  return converChatMessageContentBlockBase(c) as any;
};

export const convertChatMessageContentBlocks = (cs: any[]) =>
  cs.map(convertChatMessageContentBlock);
