
import { defineComponent } from "vue";
import { participantsModule } from "@/store/modules/participants";
import { Player } from "../../../types/Player";
import LoadingLayout from "@/components/layout/LoadingLayout.vue";
import ParticipantBlock from "@/components/participants/ParticipantBlock.vue";

export default defineComponent({
  name: "ParticipantsDisplay",
  components: { ParticipantBlock, LoadingLayout },
  data() {
    return {
      form: {
        id: ""
      }
    };
  },
  computed: {
    participants(): Player[] {
      return participantsModule.participants;
    },
    finishedInitialLoad(): boolean {
      return participantsModule.finishedInitialLoad;
    },
    favorites(): Player[] {
      return participantsModule.favoriteParticipants;
    }
  },
  methods: {
    async addFavorite() {
      await participantsModule.addFavorites(this.form.id);
    }
  }
});
