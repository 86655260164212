
import { defineComponent } from "vue";
import { participantsModule } from "@/store/modules/participants";
import { pageManagerModule } from "@/store/modules/pageManager";
import ParticipantsDisplay from "@/components/participants/ParticipantsDisplay.vue";

export default defineComponent({
  name: "Home",
  components: { ParticipantsDisplay },
  async beforeMount() {
    await participantsModule.fetchParticipants();
  },
  computed: {
    participants() {
      return participantsModule.participants;
    }
  },
  beforeRouteEnter() {
    pageManagerModule.changePageTitle("Home");
  }
});
