import { spaxios } from "@/api/spaxios";
import { Routes } from "@/api/routes";

let calledSanctum = false;

interface Props {
  email: string;
  password: string;
}

interface LoginResponse {
  success: boolean;
  errors?: {
    email?: string[];
    password?: string[];
    system?: string[];
  };
}

export async function login(props: Props): Promise<LoginResponse> {
  if (!calledSanctum) {
    calledSanctum = true;
    await spaxios.get(Routes.SANCTUM);
  }

  const response: LoginResponse = { success: true };

  try {
    await spaxios.post(Routes.LOGIN, props);
  } catch (e) {
    response.success = false;
    response.errors = {};
    if (e.response.data.message === "Too Many Attempts.") {
      response.errors.system = ["Too many login attempts"];
    } else {
      response.errors = e.response.data.errors;
    }
  }

  return response;
}
