export function objErrorReducer<T extends any, K extends keyof T>(
  obj: T,
  key: K
) {
  if (
    obj[key] &&
    Array.isArray(obj[key]) &&
    ((obj[key] as unknown) as string[]).length > 0
  ) {
    return ((obj[key] as unknown) as string[])[0];
  }
  return "";
}
